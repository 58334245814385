import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { scrollToTarget } from "../../utils"

type Props = {
  classes: any
}

class IndexMember extends Component<Props> {
  _handleOnClick = (target: string) => (event: any) => {
    scrollToTarget(target)
  }

  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="trainers"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>TRAINERS</h1>
        </div>

        <div className={classes.itemWrapper}>
          <div className="mb20">
            スクール業務や講演、世界で活動している選手のサポートなどを行っています。
          </div>
          <div className="mt20 mb40">
            <img
              src="/assets/images/nakamura_kaori.jpg"
              className={classes.listImg}
              alt="中村 果生莉"
              onClick={this._handleOnClick("nakamura_kaori")}
            />
            <img
              src="/assets/images/yamamoto_asahi.jpg"
              className={classes.listImg}
              alt="山本 朝陽"
              onClick={this._handleOnClick("yamamoto_asahi")}
            />
            <img
              src="/assets/images/nonaka_taiki.jpg"
              className={classes.listImg}
              alt="野中 泰揮"
              onClick={this._handleOnClick("nonaka_taiki")}
            />
            <img
              src="/assets/images/okada_rumi.jpg"
              className={classes.listImg}
              alt="岡田 留美"
              onClick={this._handleOnClick("okada_rumi")}
            />
            <img
              src="/assets/images/eguchi_yasuhiro.jpg"
              className={classes.listImg}
              alt="江口 泰弘"
              onClick={this._handleOnClick("eguchi_yasuhiro")}
            />
            <img
              src="/assets/images/emoto_norimi.jpg"
              className={classes.listImg}
              alt="江本 憲美"
              onClick={this._handleOnClick("emoto_norimi")}
            />
            <img
              src="/assets/images/ezure_emi.jpg"
              className={classes.listImg}
              alt="江連 恵美"
              onClick={this._handleOnClick("ezure_emi")}
            />
            <img
              src="/assets/images/nakamoto_yukiko.jpg"
              className={classes.listImg}
              alt="中本 由貴子"
              onClick={this._handleOnClick("nakamoto_yukiko")}
            />
            <img
              src="/assets/images/taniguchi_satoru.jpg"
              className={classes.listImg}
              alt="谷口 哲"
              onClick={this._handleOnClick("taniguchi_satoru")}
            />
          </div>
        </div>
        <div className="mt40">
          <hr />
          <div className={classes.itemWrapper} id="nakamura_kaori">
            <img
              src="/assets/images/nakamura_kaori.jpg"
              className={classes.img}
              alt="中村 果生莉"
            />
            <div className={classes.user}>
              <div className={classes.name1}>中村 果生莉</div>
              <div className={classes.name2}>Kaori NAKAMURA</div>
              <div className={classes.line}>
                <span style={{ color: "#1e365c" }}>
                  現在、無料カウンセリングを受け付けておりません。
                  <br />
                  <a href="http://www.okline-t.recollect.co.jp/#top04">
                    個人トレーニングは受け付けております。
                  </a>
                </span>
                <br />
                <br />
                {
                  //<a href="https://lin.ee/pELcwA5">
                  //  <img
                  //    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  //    alt="友だち追加"
                  //    height="36"
                  //  />
                  //</a>
                }
              </div>
              <div className={classes.description}>
                <p>
                  元新体操選手。5 歳から新体操を始め、14
                  歳の時、アメリカ・フロリダ州で行われた四大陸新体操選手権大会に日本代表選手として出場。団体金メダルを獲得した。
                  <br />
                  高校では、インターハイや国体、選抜大会にレギュラー出場し団体 3
                  位などの成績を収める。
                </p>
                <p>
                  オリンピック出場を目指していたが、椎間板ヘルニアが発症し高校卒業と共に現役引退。
                  <br />
                  引退後はタレントに転身し、主にスポーツ番組の MC
                  やリポーターとして活動している。
                </p>
                <p>
                  プロスポーツ選手の取材や、新体操以外の競技を間近で見る機会が増え、自身が現役の頃に強く感じていた
                  “メンタルの重要性”を改めて実感したことがきっかけとなり、メンタルトレーナーを目指す。
                </p>
                <p>
                  リコレクトメンタルトレーナー資格取得後は、リコレクト所属のメンタルトレーナーとしてアスリートを中心にメンタルトレーニングを行う。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="yamamoto_asahi">
            <img
              src="/assets/images/yamamoto_asahi.jpg"
              className={classes.img}
              alt="山本 朝陽"
            />
            <div className={classes.user}>
              <div className={classes.name1}>山本 朝陽</div>
              <div className={classes.name2}>Asahi YAMAMOTO</div>
              <div className={classes.line}>
                <span style={{ color: "#1e365c", textDecoration: "underline" }}>
                  現在、無料カウンセリング・新規での個人トレーニングを受け付けておりません。
                </span>
                <br />
                <br />
                {
                  //<a href="https://lin.ee/2Idobcf">
                  //  <img
                  //    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  //    alt="友だち追加"
                  //    height="36"
                  //  />
                  //</a>
                }
              </div>
              <div className={classes.description}>
                <p>
                  LGBT当事者。元女子サッカー選手。日本体育大学出身。正GKとして全日本大学選手権で２連覇を経験。関東大学サッカーリーグ最優秀GK賞受賞。
                  日本選抜・GKプロジェクト招集経験有り。
                </p>
                <p>
                  物心ついたときから自分の性に違和感を抱いており、高校時、性同一性障害を取り上げている番組を見て、違和感が確信へ変わる。
                  <br />
                  本当の自分の存在を周りに伝えようと決め、カミングアウト。男性として生きるために、大学卒業後性別適合手術を受ける。
                  <br />
                  幼少期に感じた違和感や自己否定感との葛藤、サッカー人生における３度の前十字靭帯断裂。
                  <br />
                  その際に感じた仲間に対しての劣等感、やらなきゃと思う気持ちとは裏腹に頑張れない自分に対しての自己嫌悪。これらがメンタルに興味を持つきっかけとなった。
                  <br />
                </p>
                <p>
                  大学卒業後はサッカー指導の道へ進みそこで練習では実力発揮できるのに、本番になると実力発揮ができなくなる選手をみた時、メンタル面の重要性を感じ「OKラインメンタルトレーニング」を学びメンタルトレーナーとして活動する。
                </p>
                <p>
                  「意図的に作る自信の作り方」をメインにジュニアアスリートを教育現場を中心にLGBT講演含め多数講演依頼を受ける。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="nonaka_taiki">
            <img
              src="/assets/images/nonaka_taiki.jpg"
              className={classes.img}
              alt="野中 泰揮"
            />
            <div className={classes.user}>
              <div className={classes.name1}>野中 泰揮</div>
              <div className={classes.name2}>Taiki NONAKA</div>
              <div className={classes.line}>
                <a href="https://lin.ee/27byU1dQh">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                    alt="友だち追加"
                    height="36"
                  />
                </a>
              </div>
              <div className={classes.description}>
                <p>
                  大学生の時に始めた飲食店でのアルバイトをきっかけに接客業の楽しさにのめり込み、外食企業に入社。現場での活躍が認められ、本部スタッフとして接客マニュアル作成・人材育成・新業態開発など全社に関わるさまざまな重要業務に携わる。
                </p>
                <p>
                  エリアの統括業務を任された際に、多くの現場スタッフと対話する中で、従業員１人１人のメンタルが成果に密接に関わっていることに気づく。組織・個人双方の成長にメンタルトレーニングが大きな可能性を秘めていることを確信し、リコレクトメンタルトレーナー資格取得すると共に、社内で人事担当に転籍。
                </p>
                <p>
                  現在人事として、社内研修・個別相談対応などにメンタルトレーニングを取り入れるなど、トレーナー資格を存分に活かしながら、経営側と従業員の間に立ち双方がＷＩＮ－ＷＩＮの関係を構築し最大の成果をあげる組織づくりに努めている。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="okada_rumi">
            <img
              src="/assets/images/okada_rumi.jpg"
              className={classes.img}
              alt="岡田 留美"
            />
            <div className={classes.user}>
              <div className={classes.name1}>岡田 留美</div>
              <div className={classes.name2}>Rumi OKADA</div>
              <div className={classes.line}>
                <a href="https://lin.ee/2IrItsW8A">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                    alt="友だち追加"
                    height="36"
                  />
                </a>
              </div>
              <div className={classes.description}>
                <p>兵庫県姫路市出身　国家資格キャリアコンサルタント保有</p>
                <p>
                  高校を卒業し縁故なし・前例なしで地元のJAに入社、本店金融課に抜擢。同期トップの成績を残すも、ストレスからアトピーが全身重症化し退職。
                </p>
                <p>
                  夫の転勤で上京後、離婚パワハラと不倫相手からのモラハラで睡眠障害・軽鬱を抱えつつ、調停・別居期間を経て協議離婚。子育てしつつ働く中で突然の倒産解雇も経験し、重なる強いストレスを一人で抱え感情に振り回される日々を送っていた。
                </p>
                <p>
                  その後、舞祭りチーム立ち上げに関わりOKラインメンタルトレーニングを導入。ダンス経験ゼロから金賞受賞チームへと導きテレビ東京ドキュメント番組に密着取材される。「自分で作れる自己肯定感」を取得させ、演舞は勿論のこと不登校・いじめ・人見知り・あがり症の克服、発達障害グレーのIQアップと障害克服などにも寄与。
                </p>
                <p>
                  しかし自身は過去の強いストレスやトラウマから、結果を出しても少しも自信が持てず自己否定感が拭えずにいたが、継続的にメンタルトレーニングを受けることで、感情に大きく左右されることなく、ありのままの自分を認め過去の自分を許せる自己肯定感へと変えていけた。
                </p>
                <p>
                  メンタルトレーニングは特別な人のものではなく、子供からビジネスパーソンまで誰もが使え、自己肯定感を上げ、目標達成を目指せる方法であること、そしてそれが個の人生にも、企業内の人材育成にも非常に効果的であることを体感していただくため、メンタルトレーナーとキャリアコンサルタントを融合させた、楽しく腹落ち・理解度の高い、行動実行が非常にしやすい内容の講座や個別レッスンを得意とする。顧客満足度は90%以上。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="eguchi_yasuhiro">
            <img
              src="/assets/images/eguchi_yasuhiro.jpg"
              className={classes.img}
              alt="江口 泰弘"
            />
            <div className={classes.user}>
              <div className={classes.name1}>江口 泰弘</div>
              <div className={classes.name2}>Yasuhiro EGUCHI</div>
              <div className={classes.line}>
                <a href="https://lin.ee/1y9NWn3SU">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                    alt="友だち追加"
                    height="36"
                  />
                </a>
              </div>
              <div className={classes.description}>
                <p>
                  理学療法士。国際 PNF
                  協会認定インストラクター候補者、ドイツ筋骨格医学会認定セラピスト。兵庫県出身、二児の父。
                </p>
                <p>
                  子どもの頃、緊張のため人前で話す事が嫌いで自己否定感が強い傾向にあった。
                  <br />
                  学生時代は野球に明け暮れ、ある日自身の練習時と試合時でのパフォーマンスの違いにショックを受ける。
                  <br />
                  大学在学中に心理学に興味を持つが、理学療法士になる事を決意し本格的に学ぶことを断念。
                  <br />
                  総合病院リハビリテーション科に勤務し、運動療法の一つである PNF
                  を学ぶため米国カリフォルニア州カイザーリハビリテーションセンターにて9ヶ月研修を修了後、ボディコンディショニング施設・PNF研究所に入職。
                  <br />
                  脳卒中後遺症の方や痛みにお困りの方（腰、肩、膝、股関節など）から、プロアスリート（テニス、相撲、野球、ゴルフ、スキー競技など）、ダンサー（バレエ、フラメンコ、ソーシャル、タップ、日舞など）や音楽家（ピアノ、ビオラ、三味線など）まで幅広い臨床経験を20年以上持つ。
                </p>
                <p>
                  その間に身体だけにアプローチしても改善しないクライアントを経験すると共に、本番で実力通りにパフォーマンスを発揮出来なかった自身の経験から「身体と心は分けることが出来ない。身体のコンディショニングだけでなく心からもサポートする」ことを志し、2013年より心理学・カウンセリングを、2017年よりリコレクトにてメンタルトレーニングを学ぶ。
                </p>
                <p>
                  現在は運動療法に心理学的アプローチ、呼吸法、ヨガなどを取り入れた施術を日々行いながら講習会やセミナーの講師、メンタルトレーナーとしても活動。
                  <br />
                  また、うつ症状や子育て中のメンタルヘルス、心身症（主に身体の痛み症状）のカウンセリング経験も持つ。
                  <br />
                  スポーツやビジネスでのここという場面で実力を発揮出来る心身の整え方を伝え、こころ・からだ両面から人を元気にすることをライフワークとしている。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="emoto_norimi">
            <img
              src="/assets/images/emoto_norimi.jpg"
              className={classes.img}
              alt="江本 憲美"
            />
            <div className={classes.user}>
              <div className={classes.name1}>江本 憲美</div>
              <div className={classes.name2}>Norimi EMOTO</div>
              <div className={classes.line}>
                <a href="https://lin.ee/ex7Gtlc">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                    alt="友だち追加"
                    height="36"
                  />
                </a>
              </div>
              <div className={classes.description}>
                <p>
                  静岡県出身　 中学時代から、バスケットボールを初め、高校では、
                  <br />
                  「もっと、全国大会に行ける高校でバスケットボールをやりたい」と
                  <br />
                  全国大会常連の強豪校に入学。
                  <br />
                  全て全国大会に出場はすることができたものの、自分の結果は補欠。
                </p>
                <p>
                  ある日、メンタルトレーニングを受けている、スポーツ選手をみて、
                  <br />
                  メンタルトレーナーという存在を知りました。
                  <br />
                  高校時代の自分を振り返った時に、私にもメンタルトレーナーがいたらな…
                  <br />
                  と思ったのが、メンタルトレーナーになったのが、きっかけです。
                </p>
                <p>
                  現在は、中学生ラグビーチームの子供たちや、親御さんへの講習会等開催。
                </p>
                <p>
                  夢や、目標に向う気持ちを大切にしたサポートに努めています。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="ezure_emi">
            <img
              src="/assets/images/ezure_emi.jpg"
              className={classes.img}
              alt="江連 恵美"
            />
            <div className={classes.user}>
              <div className={classes.name1}>江連 恵美</div>
              <div className={classes.name2}>Emi EZURE</div>
              <div className={classes.line}>
                <span style={{ color: "#1e365c" }}>
                  現在、無料カウンセリングを受け付けておりません。
                  <br />
                  <a href="http://www.okline-t.recollect.co.jp/#top04">
                    個人トレーニングは受け付けております。
                  </a>
                </span>
                <br />
                <br />
                {
                  //  <a href="https://lin.ee/pELcwA5">
                  //    <img
                  //      src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  //      alt="友だち追加"
                  //      height="36"
                  //    />
                  //  </a>
                }
              </div>
              <div className={classes.description}>
                <p>
                  元大和証券株式会社 秘書室
                  <br />
                  元JAL/ANA客室乗務員
                </p>
                <p>
                  大学卒業後、大和証券の秘書室にて勤務。
                  <br />
                  大和証券退職後、全日本空輸に入社し客室乗務員として勤務。
                  <br />
                  その後スカイマーク、日本航空の客室乗務員として勤務する。
                </p>
                <p>
                  日本航空退職後、転職先の企業にてセクシャルハラスメントの被害を受ける。同時に母親の死別を経験しストレスマネジメントの大切さを痛感する。
                  <br />
                  その後フリーランスの研修講師として活動を開始するも、オファーが来ない辛い時期を経験し、折れない心作りに取り組むべく独学でメンタルトレーニングを学び始める。
                </p>
                <p>
                  自身の経験から感情の扱い方、集中力の高め方、自己肯定感の持ち方を習得することを決意し、リコレクトにてメンタルトレーニングを学ぶ。
                </p>
                <p>
                  現在は個人向けメンタルトレーナーとして、舞台人、経営者、ビジネスマンを中心としたクライアント向けに活動をしている。
                  <br />
                  またメンタル系研修を中心に年間200本の研修に登壇している。
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="nakamoto_yukiko">
            <img
              src="/assets/images/nakamoto_yukiko.jpg"
              className={classes.img}
              alt="中本 由貴子"
            />
            <div className={classes.user}>
              <div className={classes.name1}>中本 由貴子</div>
              <div className={classes.name2}>Yukiko NAKAMOTO</div>
              <div className={classes.line}>
                <span style={{ color: "#1e365c" }}>
                  現在、無料カウンセリングを受け付けておりません。
                  <br />
                  <a href="http://www.okline-t.recollect.co.jp/#top04">
                    個人トレーニングは受け付けております。
                  </a>
                </span>
                <br />
                <br />
                {
                  //  <a href="https://lin.ee/pELcwA5">
                  //    <img
                  //      src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  //      alt="友だち追加"
                  //      height="36"
                  //    />
                  //  </a>
                }
              </div>
              <div className={classes.description}>
                <p>
                  働く母メンタルトレーナー。リコレクトオフィシャルメンタルトレーナー。
                  <br />
                  高校生の母であり、子育てと仕事の両立歴16年。
                  <br />
                  母親業・秘書・メンタルトレーナーの三足のわらじを持つ。
                </p>
                <p>
                  短大卒業後に就職した企業で、入社2年目にして部下を持つポジションにつくが、高い理想と現実とのギャップに挫折を経験。
                  <br />
                  その時、『働く人を精神面からサポートする』ことの重要性を実感し、独学で心理学や産業カウンセラーについて学び始める。
                </p>
                <p>
                  また、自身の子育て中、夢を持つ子供の『なりたい自分の実現をサポート』したいと一念発起し、目標達成をサポートするメンタルトレーナーの資格を取得。
                </p>
                <p>
                  仕事における目標設定や職場でのコミュニケーションにお困りの方。
                  <br />
                  親として、子供の目標達成実現のためどのような声掛けをしたらよいかお悩みの方。
                </p>
                <p>
                  あらゆる立場の方の問題を解決するための「こころ」の成長をサポートします！
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={classes.itemWrapper} id="taniguchi_satoru">
            <img
              src="/assets/images/taniguchi_satoru.jpg"
              className={classes.img}
              alt="谷口 哲"
            />
            <div className={classes.user}>
              <div className={classes.name1}>谷口 哲</div>
              <div className={classes.name2}>Satoru TANIGUCHI</div>
              <div className={classes.line}>
                <span style={{ color: "#1e365c" }}>
                  現在、無料カウンセリングを受け付けておりません。
                  <br />
                  <a href="http://www.okline-t.recollect.co.jp/#top04">
                    個人トレーニングは受け付けております。
                  </a>
                </span>
                <br />
                <br />
                {
                  //  <a href="https://lin.ee/pELcwA5">
                  //    <img
                  //      src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  //      alt="友だち追加"
                  //      height="36"
                  //    />
                  //  </a>
                }
              </div>
              <div className={classes.description}>
                <p>
                  日本心臓血管外科学会認定　心臓血管外科専門医・修練指導医
                  <br />
                  日本体育協会公認スポーツドクター
                </p>
                <p>
                  日本で唯一の現役心臓血管外科医＋メンタルトレーナー
                  <br />
                  幼少から大学までサッカーを経験し、いつも試合前に緊張して力を出しきれないでいた。社会人になり、日々患者さんの生死に関わるなかで、手術前のプレッシャーがサッカーの試合前の緊張に似ていることに気づき、プレッシャー克服のため、メンタルトレーナーを目指す。
                </p>
                <p>
                  現在は手術前の自分のコントロールにメンタルトレーニングの知識を活用するとともに、職場の人々の人間関係からダイエットまで幅広く、相談に乗っている。また、自分でも20kgのダイエットに成功。少年野球チームのメンタルトレーニングを担当。
                </p>
                <p>
                  今後は医学的専門知識とメンタルトレーナーの知識を生かして、心と身体、両面をサポートし、スポーツ選手の目標達成に一緒に取り組みたい。また、メンタル面からアプローチして、職場の人間関係の改善やビジネスマンのキャリアップに貢献したいと思っています。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #E60722",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #E60722",
    },
  },
  subtitle: {
    fontSize: 24,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    paddingBottom: 20,
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  listImg: {
    width: 100,
    height: 100,
    objectFit: "cover",
    objectPosition: "50% 0",
    marginRight: 20,
    borderRadius: 20,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  img: {
    width: 200,
    height: 200,
    objectFit: "cover",
    objectPosition: "50% 0",
    marginRight: 20,
    borderRadius: 20,
  },
  itemWrapper: {
    paddingTop: 40,
    paddingBottom: 40,
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 1024,
    margin: "0 auto",
  },
  user: {
    maxWidth: 700,
  },
  name1: {
    fontSize: 30,
    fontFamily: "HiraKakuPro-W6",
  },
  name2: {
    color: "#E60722",
    fontSize: 16,
    marginTop: 12,
  },
  position: {
    marginLeft: 20,
    fontSize: 16,
  },
  description: {
    marginTop: 10,
    fontSize: 14,
    letterSpacing: 0.25,
  },
  line: {
    marginTop: 20,
  },
})

export default withStyles(styles)(IndexMember)
