import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexOrganization extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="company" className={classes.root}>
        <div className={classNames(classes.componentWrapper, "title-wrapper")}>
          <h1 className={classes.title}>COMPANY</h1>
        </div>
        <div className={classes.captionWrapper}>
          <h2 className={classes.caption}>
            目標に向かい前進する人と企業を応援する会社
          </h2>
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.messageWrapper}>
            <p className={classes.message}>
              リコレクトは「目標に向かい前進する人と企業」をメンタルトレーニングを通してサポートしていきます。みなさんの目標を１つでも多く実現していくことがリコレクトの目指す未来です。そんな未来を実現していく自信を構築するための理論「OKライン」がリコレクトにはあります。
            </p>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src="/assets/images/company1.jpg"
            alt="company1"
          />
          <img
            className={classes.image}
            src="/assets/images/company2.jpg"
            alt="company2"
          />
        </div>
        <div className={classes.componentWrapper}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.columnTitle}>会社名</td>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>株式会社リコレクト</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>代表</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>森川陽太郎</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>創立</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>2009年7⽉</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>HP</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>
                  <a href="https://recollect.co.jp">https://recollect.co.jp</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    padding: 100,
    backgroundColor: "F7F8F8",
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #E60722",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #E60722",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1024,
    paddingTop: 50,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  columnTitle: {
    border: "none",
    fontWeight: "normal",
    whiteSpace: "nowrap",
    width: "10%",
    padding: 0,
    paddingBottom: 12,
  },
  table: {
    fontSize: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  tableTd: {
    border: "none",
    width: "89%",
    padding: 0,
    paddingBottom: 12,
    wordBreak: "break-all",
  },
  tableSeparator: {
    width: "1%",
    border: "none",
    padding: 0,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  captionWrapper: {
    margin: "auto",
    paddingTop: 50,
    paddingBottom: 25,
    maxWidth: 1024,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
    },
  },
  caption: {
    fontFamily: "HiraKakuPro-W6",
    fontSize: 32,
    textAlign: "left",
    lineHeight: 1.7,
    letterSpacing: 1.2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  messageContainer: {
    display: "flex",
    width: "100%",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 18,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  messageWrapper: {
    margin: "0 auto",
  },
  message: {
    maxWidth: 1024,
    fontSize: 24,
    fontFamily: "HiraKakuPro-W6",
    lineHeight: 1.75,
    letterSpacing: 0.8,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingTop: 40,
    paddingBottom: 40,
  },
  image: {
    padding: 10,
    width: 320,
    height: 245,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 320,
      objectFit: "cover",
    },
  },
})

export default withStyles(styles)(IndexOrganization)
