import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexSecret extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="secret" className={classes.root}>
        <div className={classNames(classes.componentWrapper, "title-wrapper")}>
          <h1 className={classes.title}>Secret</h1>
        </div>
        <div className={classes.listContainer}>
          <div className={classes.listWrapper}>
            <div className={classes.listTitle}>秘密事項の厳守</div>
            <ul className={classNames(classes.list, "mt20")}>
              <li className="list-square mb20 marker">
                <span className="animation">
                  トレーナーには守秘義務があり、相談された事実および相談内容が本人の許可なく第三者に伝わることはありません
                </span>
              </li>
              <li className="list-square marker">
                <span className="animation">
                  全ての個人情報は、株式会社リコレクトの
                  <a href="/privacy">プライバシーポリシー</a>
                  にしたがって適切に扱われます
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    backgroundColor: "#FFF",
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #E60722",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #E60722",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
    },
  },
  listContainer: {
    paddingTop: 40,
    display: "flex",
  },
  listWrapper: {
    maxWidth: 1024,
    margin: "0 auto",
  },
  listTitle: {
    fontSize: 30,
    paddingBottom: 16,
  },
  list: {
    paddingTop: 12,
    paddingLeft: 40,
    fontSize: 22,
    maxWidth: 1024,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      paddingLeft: 20,
    },
  },
})

export default withStyles(styles)(IndexSecret)
