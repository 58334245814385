import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Image from "../image"
import { lineHeight } from "@material-ui/system"
import { withAssetPrefix } from "gatsby"

type Props = {
  classes: any
}

class IndexMessage extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="service"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>SERVICE</h1>
        </div>
        <div className={classes.captionWrapper}>
          <h2 className={classes.caption}>
            数々のアスリートや企業、個人へのトレーニング実績を持つメンタルトレーナーによるメンタルカウンセリングサービスです
          </h2>
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.messageWrapper}>
            <ul className={classNames(classes.message, "pt20 pb20")}>
              <li className="list-square">
                対面トレーニングのプロが、皆様のストレスをケア
              </li>
              <li className="list-square">顔をあわせないカウンセリング</li>
              <li className="list-square marker">
                <span className="animation">
                  3つのご相談まで無料でお受けします
                </span>
                <br />
                ※追加でのトレーニングをご希望の方は有料にてお受けします
                <br />
                <a
                  href="http://www.okline-t.recollect.co.jp/application/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  トレーニング申し込みフォーム
                </a>
              </li>
            </ul>
            <p className={classes.message}></p>
          </div>
        </div>
        <div className={classes.captionWrapper}>
          <h2 className={classes.caption}>以下の方法でご相談いただけます</h2>
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.messageWrapper}>
            <ol className={classNames(classes.message, "pt20 pb20")}>
              <li>
                <a href="/#trainers">トレーナー一覧</a>
                よりトレーナーを選んでください
              </li>
              <li>LINEを使って友だち追加を行います</li>
              <li>トレーナーにご相談内容をお伝え下さい</li>
              <li>トレーナーより3営業日以内にご返答いたします</li>
            </ol>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    backgroundColor: "#FFF",
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #E60722",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #E60722",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  container: {
    paddingLeft: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  captionWrapper: {
    margin: "auto",
    paddingTop: 50,
    paddingBottom: 25,
    maxWidth: 1024,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
    },
  },
  caption: {
    fontFamily: "HiraKakuPro-W6",
    fontSize: 32,
    textAlign: "left",
    lineHeight: 1.7,
    letterSpacing: 1.2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  messageContainer: {
    display: "flex",
    width: "100%",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 18,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  messageWrapper: {
    margin: "0 auto",
  },
  message: {
    maxWidth: 1024,
    fontSize: 24,
    fontFamily: "HiraKakuPro-W6",
    lineHeight: 1.75,
    letterSpacing: 0.8,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  messageName: {
    marginTop: 80,
    maxWidth: 1024,
    fontSize: 24,
    fontFamily: "HiraKakuPro-W6",
    lineHeight: 1.75,
    letterSpacing: 0.8,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      fontSize: 18,
    },
  },
  nameBorder: {
    borderBottom: "3px solid #E60722",
    paddingBottom: 4,
  },
})

export default withStyles(styles)(IndexMessage)
