import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexMessage extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="message"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>MESSAGE</h1>
        </div>
        <div className={classes.captionWrapper}>
          <h2 className={classes.caption}>
            ストレスに悩む方へ
            <br />
            私たちに何か出来ることはないかと考えました
          </h2>
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.messageWrapper}>
            <p className={classes.message}>皆さんこんにちは。</p>
            <p className={classes.message}>
              新型コロナウィルス拡大によって生活環境が大きく変化された方がとても多いと思います。
            </p>
            <p className={classes.message}>
              自粛を呼びかけるニュースや感染者数を目にする毎日は不安や恐怖を助長させたり、通勤や通学、お買い物などで外出しなければならない時にも不安を感じる要因は常に存在していて、なかなかそのストレスを自分1人では解消できないという方や、中にはパートナーやお子様などに対しての声がけやサポートをしたいけれど、どんな対応をしたらいいのか分からないという方も多くいらっしゃるのではないかと感じます。
            </p>
            <p className={classes.message}>
              そんな状況の中で、私たちに何か出来ることはないかと考えました。
            </p>
            <ul className={classNames(classes.message, "pt20 pb20")}>
              <li className="list-square">
                新型コロナウィルス拡大によって心のバランスが崩れていると感じる方
              </li>
              <li className="list-square">
                おうち時間が増えることによってストレスがたまっていると感じる方
              </li>
              <li className="list-square">
                今後の事を考えると不安でどうしたらいいのか分からない方
              </li>
              <li className="list-square">
                心の内を誰にも相談できないと感じる方
              </li>
              <li className="list-square">
                大切な方を何かサポートしたいけれど適切な声がけが分からない方
                など
              </li>
            </ul>
            <p className={classNames(classes.message, "marker")}>
              <span className="animation">
                まずはオンラインでお手軽・お気軽に皆様と繋がれる場所を提供させていただきたいと思い、プラスメンタルを始めさせていただくことになりました。
              </span>
            </p>
            <p className={classes.message}>
              どんな事でも構いません。
              <br />
              頭の整理ができていなくても大丈夫です。
              <br />
              今の状況をありのままお話ください。
            </p>
            <p className={classes.message}>
              一緒にゆっくりと、今の状況を改善していけるようにトレーナー一同、全力でサポートさせていただきます。
            </p>
            <p className={classes.messageName}>
              2021年2月13日
              <br />
              メンタルトレーナー 中村果生莉
            </p>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #E60722",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #E60722",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  container: {
    paddingLeft: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  captionWrapper: {
    margin: "auto",
    paddingTop: 50,
    paddingBottom: 25,
    maxWidth: 1024,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
    },
  },
  caption: {
    fontFamily: "HiraKakuPro-W6",
    fontSize: 32,
    textAlign: "left",
    lineHeight: 1.7,
    letterSpacing: 1.2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  messageContainer: {
    display: "flex",
    width: "100%",
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 18,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  messageWrapper: {
    margin: "0 auto",
  },
  message: {
    maxWidth: 1024,
    fontSize: 24,
    fontFamily: "HiraKakuPro-W6",
    lineHeight: 1.75,
    letterSpacing: 0.8,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  messageName: {
    marginTop: 80,
    maxWidth: 1024,
    fontSize: 24,
    fontFamily: "HiraKakuPro-W6",
    lineHeight: 1.75,
    letterSpacing: 0.8,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      fontSize: 18,
    },
  },
})

export default withStyles(styles)(IndexMessage)
